import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Caret from './Caret';
import Placeholder from '../images/placeholder.svg';
import { theme, grid } from './theme';

const StoriesList = ({ stories }) => {
  return (
    <StyledStoriesList>
      {stories.map(({ node: { title, id, publishedAt, excerpt, mainImage, slug } }) => {

        return (
          <Story key={id}>
            <Link to={`/post/${slug.current}`} className="story-link">
              {mainImage ? (
                <Img className="story-image" fixed={mainImage.asset.fixed} alt={mainImage.alt} />
              ) : (
                <div className="story-image">
                  <img className="placeholder-image" src={Placeholder} alt={title} />
                </div>
              )}
              <h2 className="story-title">{title}</h2>
              <span className="published-date">{publishedAt}</span>
              <p className="excerpt">{excerpt}... <Caret color={theme.red} /></p> 
            </Link>
          </Story>
        );
      })}
    </StyledStoriesList>
  );
};

const StyledStoriesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Story = styled.li`
  margin-bottom: 2rem;
  
  .story-link {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.purple}; 
  }
  .story-image {
    float: left;
    margin-right: 1rem;
    margin-top: 1rem;
    grid-area: image;
    ${grid.enabled`
      float: none;
      margin-left: auto;
      margin-right: auto;
    `}
  }
  .story-title {
    color: ${({ theme }) => theme.red};
    grid-area: title;
  }
  .published-date {
    color: ${({ theme }) => theme.purple};
    margin-bottom: 1.5rem;
    display: block;
    grid-area: date;
  }
  .excerpt {
    font-weight: ${({ theme }) => theme.font.semibold};
    grid-area: excerpt;
  }

  ${grid.enabled`
    .story-link {
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-template-rows: auto auto 1fr;
      grid-column-gap: 1.5rem;
      grid-template-areas: 
        "image title"
        "image date"
        "image excerpt";
    }
  `}
`;

export { StyledStoriesList, Story };
export default StoriesList;