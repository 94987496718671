import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import PageSidebar from './PageSidebar';
import Content from './Content';
import Accordion from './Accordion';
import ProductsList from './ProductsList';

import useSiteContext from './SiteContext';
import { media } from './theme';

const Page = ({ title, _rawContent, products, mainImage, accordion, className }) => {

  const { viewport, mobile } = useSiteContext();

  return (
    <PageWrapper className={classNames('page-wrapper', className)} viewHeight={viewport.height}>
      <PageSidebar title={title} mainImage={mainImage} />
      <div className="page-content">
        {!mobile && (
          <h1 className="page-title">{title}</h1>
        )}
        {_rawContent && (
          <Content main>
            {_rawContent}
          </Content>
        )}
        {accordion && (
          <Accordion accordion={accordion} />
        )}
        {products && (
          <ProductsList products={products} />
        )}
      </div>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  .page-title {
    /* color: ${({ theme }) => theme.red}; */
    padding-left: 1rem;
    padding-right: 1rem;
  }
  ${media.break`
    display: flex;
    flex-flow: row wrap;
  `}

  .page-content {
    ${media.break`
      flex-grow: 1;
      order: 1;
      flex: 0 1 800px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4rem;
      padding-left: 3rem;
      padding-right: 3rem;
      overflow: scroll;
      height: ${({ viewHeight, theme }) => viewHeight - theme.headerHeight.desktop}px;
      font-size: 2rem;
    `}
  } 
`;


export { PageWrapper };
export default Page;