import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import Footer from './Footer';

import useSiteContext from './SiteContext';
import emailIcon from '../images/email.svg';
import phoneIcon from '../images/phone.svg';
import { formatPhone} from './utils';
import { media } from './theme';

const Contact = ({ big = false, footer = false, className }) => {

  const { contact: { email, phone, address } } = useSiteContext();
  
  return (
    <ContactInfoBox big={big} className={classNames('contact', className, { big })}>
      {big && (
        <h3 className="contact__title">Contact Diane</h3>
      )}
      {email && (
        <a className="contact__link" href={`mailto:${email}`}>
          <img className="contact__icon" src={emailIcon} alt={`Email us at ${email}`}/>
          {big && (
            <span className="contact__label">{email}</span>
          )}
        </a>
      )}
      {phone && (
        <a className="contact__link" href={`tel:${formatPhone(phone).href}`}>
          <img className="contact__icon" src={phoneIcon} alt={`Call us at ${formatPhone(phone).href}`} />
          {big && (
            <span className="contact__label">{formatPhone(phone).display}</span>
          )}
        </a>
      )}
      {address && big && (
        <p className="contact__address">{address}</p>
      )}
      {footer && (
        <Footer />
      )}
    </ContactInfoBox>
  );
};

const ContactInfoBox = styled.div`
  background: ${({ theme }) => theme.red};
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem;
  flex-grow: 0;
  justify-content: ${({ big }) => big ? 'flex-start' : 'space-evenly'};
  flex-flow: row wrap;
  font-size: 1.6rem;
  ${media.break`
    width: auto;
    height: ${({ big }) => big ? 'auto' : '100%'};
  `}
  .contact {
    &__title {
      color: white;
      flex: 100%;
      font-size: 2rem;
    }
  
    &__link {
      margin-left: ${({ big }) => big ? 0 : '.5rem'};
      margin-right: .5rem;
      color: white;
      text-decoration: none;
      font-weight: ${({ theme }) => theme.font.semibold};
      margin-top: .5rem;
      margin-bottom: .5rem;
      :first-child {
        margin-left: 1rem;
      }
      :last-child {
        margin-right: 1rem;
      }
    }
    &__icon {
      display: inline;
      vertical-align: middle;
    }
    &__label {
      margin-left: .5rem;
    }
    &__address {
      color: white;
      margin: 1rem 0;
      flex: 100%;
    }
  }
`;

export default Contact;