import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useSpring, useTransition, animated } from 'react-spring';

import { media } from '../theme';

const MailerLiteForm = ({ open, formCode }) => {
  
  /**
   * Animation Logic
   */
  
  const [formHeight, setFormHeight] = useState(0);
  // const formRef = useRef(null);
  // useLayoutEffect(() => setFormHeight(formRef.current.clientHeight), [formRef.current]);
  const formSpring = useSpring({ height: open ? formHeight : 0 });
  const formTransition = useTransition(open, null, {
    from: {height: 0},
    enter: {height: 200},
    leave: { height: 0}
  })
  return formTransition.map(({item, props, key}) => {
    return item && 
    <StyledForm 
    key={key}
      className="subscribe-form"  
      style={props} 
      dangerouslySetInnerHTML={{ __html: formCode.code }} 
    />
  }
  );
};

const StyledForm = styled(animated.div)`
  overflow: hidden;
  ${media.break`
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: ${({ theme }) => theme.green};
  `}
`;

export default MailerLiteForm;