import React from 'react';
import styled from 'styled-components';

import { media } from './theme';

const Footer = ({ fullscreen = false, white = false }) => {
  return (
    <StyledFooter fullscreen={fullscreen} white={white}>Website by <a href="https://creativedistillery.com" target="_blank" rel="nofollow noreferrer">Creative Distillery</a></StyledFooter>
  );
};

const StyledFooter = styled.p`
  color: ${({ theme, white }) => white ? 'white' : theme.red};
  /* opacity: .75; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  a {
    color: ${({ theme, white }) => white ? 'white' : theme.red};
  }

  ${media.break`
    margin-bottom: 0;
    ${({ fullscreen }) => fullscreen && `
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    `}
  `}
`;

export default Footer;