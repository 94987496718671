import { useState, useEffect } from 'react';

function formatPhone(num) {
  const area = num.slice(0, 3);
  const first = num.slice(3, 6);
  const last = num.slice(6, 10);
  return {
    href: `(${area}) ${first}-${last}`,
    display: `${area}-${first}-${last}`
  }
}

function useWindowSize() {
  
  function getSize() {
    const isClient = typeof window === 'object';
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  
  const [windowSize, setWindowSize] = useState(getSize);
  
  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) {
      return false;
    }
    
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export { formatPhone, useWindowSize };