import React from 'react';
import styled from 'styled-components';

import useSiteContext from './SiteContext';

const NavToggle = () => {

  const {navOpen, toggleNav} = useSiteContext();

  return (
    <ToggleButton open={navOpen} onClick={() => toggleNav(!navOpen)}>
      <span /><span /><span />
    </ToggleButton>
  );
};

const ToggleButton = styled.button`
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.red};
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  outline: none;
  z-index: 1;
  span {
    background: white;
    position: absolute;
    width: 60%;
    height: 3px;
    border-radius: 10rem;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: .25s;
    :nth-child(1) {
      top: ${({ open }) => open ? '50%' : '33%'};
      transform: translateX(-50%) translateY(-50%) rotate(${({ open }) => open ? '45deg' : '0deg'});
    }
    :nth-child(2) {
      opacity: ${({ open }) => open ? 0 : 1};
    }
    :nth-child(3) {
      top: ${({ open }) => open ? '50%' : '66%'};
      transform: translateX(-50%) translateY(-50%) rotate(${({ open }) => open ? '-45deg' : '0deg'});
    }
  }
`;

export default NavToggle;