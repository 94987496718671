import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';


const Head = ({ seo, home, location }) => {

  const {sanitySiteSettings: { newsletterForm }} = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        newsletterForm {
          universalSnippet {
            code
          }
        }
      }
    }
  `)

  const { seoSettings, title } = seo;
  
  const pageTitle = seoSettings && seoSettings.titleTag ? seoSettings.titleTag : title;
  const siteTitle = 'Diane Williams';
  const fullTitle = home ? siteTitle : `${pageTitle} | ${siteTitle}`;
  return (
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/qis8ego.css" />
      <title>{fullTitle}</title>
      {seoSettings && seoSettings.metaDescription && (
        <meta name="description" content={seoSettings.metaDescription} />
      )}
      <script>{newsletterForm.universalSnippet.code}</script>
      {/* <link rel="canonical" href={`${location.href}`} /> */}
      <meta name="google-site-verification" content="Q1pz62Nj-jTesV5YMtji9jY54fZu7BNf-nzzND0SPT0" />
    </Helmet>
  );
};

export default Head;