import React from 'react';
import { Link } from "gatsby";
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';

import Contact from './Contact';
import SubscribeForm from './SubscribeForm';

import useSiteContext from './SiteContext';
import { media } from './theme';

const Nav = () => {

  const { mobile, navOpen, viewport } = useSiteContext();

  const navTransition = useTransition(navOpen, null, {
    from: {
      transform: mobile ? 'translateY(-100%)' : 'none',
    },
    enter: {
      transform: mobile ? 'translateY(0)' : 'none'
    },
    leave: {
      transform: mobile ? 'translateY(-100%)' : 'none',
    }
  });

  return navTransition.map(({ item, key, props }) => (item || !mobile) &&
    <HeaderNav className="nav" viewWidth={viewport.width} viewHeight={viewport.height} style={props} key={key}>
      <NavMenu className="nav__menu">
        <li>
          <Link to="/about" className="menu-item" activeClassName="current">
            About
          </Link>
        </li>
        <li>
          <Link to="/gallery" className="menu-item" activeClassName="current">
            Gallery
          </Link>
        </li>
        <li>
          <Link to="/news" className="menu-item" activeClassName="current">
            News
          </Link>
        </li>
        <li>
          <Link to="/store" className="menu-item" activeClassName="current">
            Store
          </Link>
        </li>
      </NavMenu>
      {mobile && (
        <>
          <SubscribeForm />
          <Contact />
        </>
      )}
    </HeaderNav>
  );
};


const HeaderNav = styled(animated.nav)`
  flex-grow: 1;
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ viewWidth }) => viewWidth}px;
  height: ${({ viewHeight }) => viewHeight}px;
  background: ${({ theme }) => theme.purple};
  text-align: center;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1;
  ${media.break`
    flex-direction: row;
    position: static;
    transform: none;
    width: auto;
    height: auto;
    background: transparent;
    padding-top: 0;
    height: 100%;
  `}
  a {
    color: white;
    text-decoration: none;
    font-weight: ${({ theme }) => theme.font.semibold};
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: 2rem;
    &.current {
      color: ${({ theme }) => theme.green};
      text-decoration: underline;
    }
    ${media.break`
      font-size: 1.4rem;
    `}
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${media.break`
    flex-direction: row;
    margin-bottom: 0;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    li {
      height: 100%;
    }
  `}
  a {
    padding-bottom: 3rem;
    display: block;
    ${media.break`
      margin-bottom: 0;
      padding-bottom: 0;
      height: 100%;
      display: flex;
      align-items: center;
    `}
  }
`;

export default Nav;