import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { animated, useSpring } from 'react-spring';

import Content from './Content';
import Caret from './Caret';
import { theme } from './theme';

const AccordionSection = ({ title, _rawContent, slug, label }) => {
  const [expanded, setExpanded] = useState(false);
  const panelRef = useRef(null);
  const [panelHeight, setPanelHeight] = useState(0);
  useLayoutEffect(() => {
    if (panelRef.current) {
      setPanelHeight(panelRef.current.scrollHeight);
    }
  }, []);

  const panelSpring = useSpring({ 
    height: expanded ? panelHeight : 0 ,
    paddingTop: expanded ? '1rem': '0rem',
    paddingBottom: expanded ? '1rem' : '0rem',
  });

  return (
    <>
      <AccordionHeader className={classNames('accordion-header', { expanded })} aria-expanded={expanded}>
        <AccordionButton className="accordion-button" onClick={() => setExpanded(!expanded)} aria-controls={slug.current}>
          {label}
          <Caret color={theme.red} rotate={expanded ? '90deg' : '0deg'} />
        </AccordionButton>
      </AccordionHeader>
      <AccordionPanel 
        className={classNames('accordion-panel', { expanded })} 
        // expanded={expanded} 
        id={slug.current} 
        ref={panelRef} 
        // panelHeight={panelHeight}
        style={{
          height: !panelRef.current ? 'auto' : panelSpring.height,
          ...panelSpring
        }}
      >
        <h2 className="accordion-title">{title}</h2>
        {_rawContent && (
          <Content className="accordion-content">{_rawContent}</Content>
        )}
      </AccordionPanel>
    </>
  );
};

const AccordionHeader = styled.header`
  
`;

const AccordionButton = styled.button`
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.red};
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.semibold};
  width: 100%;
  text-align: left;
  padding: 1rem 0;
  padding-right: 1rem;
  outline-color: ${({ theme }) => theme.red};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const AccordionPanel = styled(animated.div)`
  overflow: hidden;
  border-bottom: 2px solid ${({ theme }) => theme.grey};
  h1, h2, .accordion-title {
    color: ${({ theme }) => theme.red};
  }
  blockquote {
    color: ${({ theme }) => theme.purple};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: 2rem;
    margin: 0 1rem 1rem;
  }

  h4, h3 {
    color: ${({ theme }) => theme.purple};
  }
`;
export default AccordionSection;