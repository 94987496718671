import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import classNames from 'classnames';

import Logo from '../images/diane-williams-logo.svg';


import { media } from './theme';
import Nav from './Nav';
import NavToggle from './NavToggle';
import Contact from './Contact';
import useSiteContext from './SiteContext';

const HeaderNav = ({ className }) => {

  const { mobile } = useSiteContext();

  return (
    <StyledHeader className={classNames('header', className)}>
      <HeaderLogo className="header__logo" to="/">
        <img className="logo" src={Logo} alt="Diane Williams" />
      </HeaderLogo>
      <Nav />
      {mobile && (
        <NavToggle />
      )}
      {!mobile && (
        <Contact />
      )}
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  background: ${({ theme }) => theme.purple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.headerHeight.mobile};
  padding-left: 1rem;
  z-index: 1;
  ${media.break`
    justify-content: space-between;
    padding-left: 4rem;
    height: ${({ theme }) => theme.headerHeight.desktop};
  `}
`;

const HeaderLogo = styled(Link)`
  display: block;
  position: relative;
  z-index: 2;
`;



export default HeaderNav
