import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Caret from './Caret';
import MailerLiteForm from './form/MailerLiteForm';

import { media } from './theme';

const SubscribeForm = () => {


  const {sanitySiteSettings: { newsletterForm }} = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        newsletterForm {
          universalSnippet {
            code
          }
          formCode {
            code
          }
        }
      }
    }
  `)


    const handleClick = () => {
      window['ml_account']('webforms', '3991714', 'q2h5l9', 'show');
    }

  return (
    <FormWrapper>
      <h3 className="form-title">
        {/* <button className="form-toggle" onClick={() => toggleForm(!formOpen)}> */}
        <button className="form-toggle" onClick={handleClick}>
          Newsletter Signup <Caret thick />
        </button>
      </h3>

      {/* <Form open={formOpen} /> */}
      {/* <MailerLiteForm open={formOpen} formCode={newsletterForm.formCode} /> */}

    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  background: ${({ theme }) => theme.green};
  width: 100%;
  padding: 2rem;
  z-index: 1;
  ${media.break`
    position: relative;
  `}
  .form-title {
    margin: 0;
    color: white;
    text-align: center;
    font-size: 2.4rem
  }
  .form-toggle {
    background: transparent;
    color: inherit;
    border: 0;
    font-family: inherit;
    cursor: pointer;
    outline: none;
  }

`;

export default SubscribeForm;