import { css } from 'styled-components';

const theme = {
  font: {
    family: 'gill-sans-nova, sans-serif;',
    headerFamily: 'schoolbook, serif;',
    normal: 400,
    regular: 400,
    semibold: 500,
    bold: 700
  },
  dark: '#242324',
  green: '#29C695',
  purple: '#330030',
  red: '#B30035',
  lightPurple: '#591646',
  yellow: '#E4E2BF',
  grey: '#979797',
  grid: {
    enabled: true,
  },
  headerHeight: {
    mobile: 50,
    desktop: 66
  },
  sizes: {
    break: 768,
    large: 1024
  }
}


const media = Object.keys(theme.sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${theme.sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const grid = Object.keys(theme.grid).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @supports (display: grid) {
      ${css(...args)}
    }
  `;
  return theme.grid.enabled ? acc : null;
}, {});

export { theme, media, grid };