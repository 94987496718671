import React from 'react';
import styled from 'styled-components';

import AccordionSection from './AccordionSection';
import { media } from './theme';

const Accordion = ({ accordion }) => {
  return (
    <StyledAccordion>
      {accordion.map(acc => (
        <AccordionSection {...acc} key={acc.id} />
      ))}
    </StyledAccordion>
  );
};

const StyledAccordion = styled.div`
  padding: 1rem;
  ${media.break`
    order: 3;
    padding-right: 4rem;
  `}
`;
export default Accordion;