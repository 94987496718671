import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const Caret = ({ color = 'white', rotate = 0, thick = false }) => {

  const caretSpring = useSpring({ 
    transform: `rotate(${rotate})`
   });

  return (
    <CaretSpan color={color} rotate={rotate} thick={thick} style={caretSpring} />
  );
};

const CaretSpan = styled(animated.span)`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  margin-bottom: 1px;
  ::before {
    position: absolute;
    content: '';
    width: 10px;
    width: ${({ thick }) => thick ? '13px' : '10px'};
    height: 2px;
    height: ${({ thick }) => thick ? '4px' : '2px'};
    background: ${({ color }) => color};
    top: 50%;
    top: ${({ thick }) => thick ? 'calc(50% - 1.5px)' : '50%'};
    right: 50%;
    border-radius: 10rem;
    transform:  translateY(-50%) translateX(50%) rotate(-45deg);
    transform-origin: right;
  }
  ::after {
    position: absolute;
    content: '';
    width: 10px;
    width: ${({ thick }) => thick ? '13px' : '10px'};
    height: 2px;
    height: ${({ thick }) => thick ? '4px' : '2px'};
    background: ${({ color }) => color};
    border-radius: 10rem;
    top: 50%;
    top: calc(50% + 1px);
    right: 50%;
    transform: translateY(-50%) translateX(50%) rotate(45deg);
    transform-origin: right;
  }
`;  

export default Caret;