import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { StyledStoriesList, Story } from './StoriesList';
import Placeholder from '../images/placeholder.svg';
import Content from './Content';

const ProductsList = ({ products }) => {
  console.log(products);
  return (
    <StyledStoriesList className="product-list">
      {products.map(({ id, title, slug, mainImage, price, _rawDescription, buyButton }) => (
        <Product key={id} className="product">
          <div to={`/product/${slug.current}`} className="story-link product-link">
            {mainImage ? (
              <Img className="story-image" fixed={mainImage.asset.fixed} alt={mainImage.alt} />
            ) : (
              <div className="story-image">
                <img className="placeholder-image" src={Placeholder} alt={title} />
              </div>
            )}
            <h2 className="story-title">{title}</h2>
            <span className="price">{price}</span>
            {_rawDescription && (
              <Content className="product-description">{_rawDescription}</Content>
            )}
            <div className="buy-button" dangerouslySetInnerHTML={{ __html: buyButton.code }} />
          </div>
        </Product>
      ))}
    </StyledStoriesList>
  );
};

const Product = styled(Story)`
  .product-link {
    grid-template-columns: 150px 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: 
    "image title"
    "image price"
    "image description"
    "image button";
  }
  .price {
    grid-area: price;
  }
  .product-description {
    grid-area: description;
    padding-left: 0;
  }
  .buy-button {
    grid-area: button;
  }
`;
export default ProductsList;