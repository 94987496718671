import React from 'react';

import Contact from './Contact';
import useSiteContext from './SiteContext';

const MobileFooter = () => {

  const { mobile } = useSiteContext();

  return mobile ? <Contact big footer /> : null;
};

export default MobileFooter;