import React from 'react';
import { ThemeProvider } from 'styled-components';

import HeaderNav from './HeaderNav';
import MobileFooter from './MobileFooter';
import Head from './Head';

import { theme } from './theme';
import GlobalStyle from './GlobalStyle';
import { SiteContextProvider } from './SiteContext';

const Wrapper = ({ children, seo, home = false, location }) => {
  
  return (
    <ThemeProvider theme={theme}>
      <SiteContextProvider>
        <Head seo={seo} home={home} location={location} />
        <HeaderNav />
        {children}
        <MobileFooter />
        <GlobalStyle />
      </SiteContextProvider>
    </ThemeProvider>
  );
};

export default Wrapper;