import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished'; 
import Img from 'gatsby-image';
import classNames from 'classnames';

import SubscribeForm from './SubscribeForm';
import Contact from './Contact';

import texture from '../images/texture.jpg';
import useSiteContext from './SiteContext';
import { media } from './theme';

import Footer from './Footer';


const PageSidebar = ({ title, mainImage, className, noMobile = false }) => {

  const { contact: { sidebarImage }, viewport, mobile } = useSiteContext();

  const image = (mainImage || sidebarImage) ? (mainImage ? mainImage : sidebarImage) : false;
  
  return (
    <PageAside viewHeight={viewport.height} className={classNames('page-aside', className)} noMobile={noMobile}>
        {!mobile && (
          <SubscribeForm />
        )}
        {mobile && (
          <h1 className="page-title">{title}</h1>
        )}
        {image && (
          <Img className="main-image" fluid={image.asset.fluid} alt={image.alt} />
        )}
        {!mobile && (
          <>
            <Contact className="aside-contact" big />
            <Footer />
          </>
        )}
      </PageAside>
  );
};

const PageAside = styled.aside`
  padding: 2rem;
  background-color: ${({ theme }) => rgba(theme.yellow, .73)};
  position: relative;
  overflow: hidden;
  display: ${({ noMobile }) => noMobile ? 'none' : 'block'};
  ::before {
    background-image: url(${texture});
    content: '';
    filter: blur(1px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  ${media.break`
    order: 2;
    flex: 1 0 300px;
    max-width: 600px;
    height: ${({ theme }) => `calc(100vh - ${theme.headerHeight.desktop}px)`};
    height: ${({ viewHeight, theme }) => viewHeight - theme.headerHeight.desktop}px;
    padding-top: 0;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .main-image {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  `}
`;

export default PageSidebar;