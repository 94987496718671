import React, { useContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useWindowSize } from './utils';
import { theme } from './theme';

const SiteContext = React.createContext();

const SiteContextProvider = ({ children }) => {

  const siteProps = useStaticQuery(graphql`
    {
      sanitySiteSettings(_id: {eq: "siteSettings"}) {
        contact {
          address
          email
          phone
          sidebarImage {
            alt
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        customCSS {
          code
        }
      }
    }
  `);

  const viewport = useWindowSize();

  const [navOpen, toggleNav] = useState(false);

  return (
    <SiteContext.Provider value={{
      ...siteProps.sanitySiteSettings,
      viewport,
      navOpen,
      toggleNav,
      mobile: viewport.width < theme.sizes.break
    }}>
      {children}
    </SiteContext.Provider>
  );
}

const useSiteContext = () => useContext(SiteContext);

export { SiteContextProvider, SiteContext };
export default useSiteContext;