import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import classNames from 'classnames';

const Content = ({ children, className, main = false }) => {
  const link = ({ children, mark: { href, external  } }) => {
    return external ? (
      <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
    ) : (
      <a href={href}>{children}</a>
    )
  }
    
  return (
    <ContentWrapper className={classNames('content-wrapper', className)} as={main ? 'main' : 'div'}>
      <BlockContent blocks={children} serializers={{marks: { link }}} projectId="n7upy37n" dataset="production" />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  font-weight: ${({ theme }) => theme.font.semibold};
  padding: 1rem;
  line-height: 1.6;
  &.post-content {
    font-size: 1.8rem;
  }
`;

export default Content;